<template>
  <div v-if="!loading && show">
    <NewFirstStepsComponent :achievements="achievements" :onlyAccountant="true" />
  </div>
  <div v-else>
    <div v-show="show" class="skeleton-new" />
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { isBefore } from "date-fns"
import { mapGetters, mapActions } from "vuex"
import NewFirstStepsComponent from "@/components/NewFirstStepsComponent.vue"
import { firstStepsCookieBaseName, checkAllStepsCompleted } from "@/utils/appHelpers"

export default {
  components: {
    NewFirstStepsComponent
  },
  data() {
    return {
      loading: true,
      show: true,
      catalogAccounts: false,
      bankConciliator: false,
      accountingAdjustments: false,
      accountingReports: false,
      accountantSpace: false,
      achievements: [
        {
          id: "badge-catalogAccounts",
          title: "accountant.catalogAccounts",
          actions: ["start"],
          link: "/category",
          stepName: "catalog accounts"
        },
        {
          id: "badge-bankConciliator",
          title: "accountant.bankConciliator",
          actions: ["start"],
          link: "/conciliation",
          stepName: "bank reconciliation"
        },
        {
          id: "badge-accountingAdjustments",
          title: "accountant.accountingAdjustments",
          actions: ["start"],
          link: "/ledger/documents",
          stepName: "accounting adjustments"
        },
        {
          id: "badge-accountingReports",
          title: "accountant.accountingReports",
          actions: ["start"],
          link: "/report#reportAccounting",
          stepName: "reports"
        },
        {
          id: "badge-accountantSpace",
          title: "accountant.accountantSpace",
          actions: ["start"],
          link: "/accountant/home",
          stepName: "accountant space"
        },
      ],
      skipModal: {
        title: "",
        text: "",
        help: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      company: "session/company",
      user: "session/user",
      appVersion: "app/appVersion"
    }),

    notShowAgainCookieName() {
      return `${firstStepsCookieBaseName}-no-show-${this.company.id}`
    },

    urlCatalogAccountsVisited() {
      return `${firstStepsCookieBaseName}-catAcc-visited-${this.company.id}`
    },

    urlBankConciliatorVisited() {
      return `${firstStepsCookieBaseName}-bankCon-visited-${this.company.id}`
    },

    urlAccountingAdjustmentsVisited() {
      return `${firstStepsCookieBaseName}-accAdj-visited-${this.company.id}`
    },

    urlAccountingReportsVisited() {
      return `${firstStepsCookieBaseName}-accRpts-visited-${this.company.id}`
    },

    urlAccountantSpaceVisited() {
      return `${firstStepsCookieBaseName}-accSpace-visited-${this.company.id}`
    },
  },
  async mounted() {
    if(Cookies.get(this.notShowAgainCookieName)) {
      this.loading = false;
      this.show = false;
      return;
    }

    await this.init()
    this.setInfo()
  },
  methods: {
    ...mapActions({
      setLoading: "config/setLoading",
      setError: "config/setError"
    }),
    async init() {
      this.loading = true

      this.catalogAccounts = Cookies.get(this.urlCatalogAccountsVisited)
      this.bankConciliator = Cookies.get(this.urlBankConciliatorVisited)
      this.accountingAdjustments = Cookies.get(this.urlAccountingAdjustmentsVisited)
      this.accountingReports = Cookies.get(this.urlAccountingReportsVisited)
      this.accountantSpace = Cookies.get(this.urlAccountantSpaceVisited)

      //this.loading = false
    },
    getIndex(id) {
      return this.achievements.findIndex(achievement => achievement.id === id)
    },
    setInfo() {
      let catalogAccounts = this.getIndex("badge-catalogAccounts");
      let bankConciliator = this.getIndex("badge-bankConciliator");
      let accountingAdjustments = this.getIndex("badge-accountingAdjustments");
      let accountingReports = this.getIndex("badge-accountingReports");
      let accountantSpace = this.getIndex("badge-accountantSpace");

      if (catalogAccounts !== -1 && this.catalogAccounts) {
        this.achievements[catalogAccounts].actions = ["completed"]
      }

      if (bankConciliator !== -1 && this.bankConciliator) {
        this.achievements[bankConciliator].actions = ["completed"]
      }

      if (accountingAdjustments !== -1 && this.accountingAdjustments) {
        this.achievements[accountingAdjustments].actions = ["completed"]
      }

      if (accountingReports !== -1 && this.accountingReports) {
        this.achievements[accountingReports].actions = ["completed"]
      }

      if (accountantSpace !== -1 && this.accountantSpace) {
        this.achievements[accountantSpace].actions = ["completed"]
      }

      if(!Cookies.get(this.notShowAgainCookieName) &&
         checkAllStepsCompleted(this.achievements) &&
         isBefore(new Date(this.company.registryDate.split(" ")[0]), new Date("2023/01/09"))) {
          Cookies.set(this.notShowAgainCookieName, true, {
            expires: 30,
            domain: ".alegra.com",
            sameSite: "strict",
            secure: true
          })
          this.show = false;
      }

      this.loading = false
    }
  }
}
</script>
