// mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload
  },
  SET_ERROR: (state, payload) => {
    state.error = payload
  }
}

export default mutations
