const actions = {
  setLoading({ commit }, payload) {
    commit("SET_LOADING", payload)
  },
  setError({ commit }, payload) {
    commit("SET_ERROR", payload)
  }
}

export default actions
