import axios from "axios";
import { baseHubspotApiUrl, hubspotApiToken } from "@/utils/appHelpers"

export const getCompanyAttributionRequest = (email) => {
  const request = axios.get(`${baseHubspotApiUrl}/company-attribution?email=${email}`, {
    headers: {
      Authorization: `Bearer ${hubspotApiToken}`
    }
  });

  // request.catch(error => {
  //   console.log(error)
  // });

  return request;
};
