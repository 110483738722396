<template>
  <div v-if="!loading && show">
    <NewFirstStepsComponent :achievements="achievements" :skipModal="skipModal" />
  </div>
  <div v-else>
    <div v-show="show" class="skeleton-new" />
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { isBefore } from "date-fns"
import NewFirstStepsComponent from "@/components/NewFirstStepsComponent.vue"
import { mapGetters, mapActions } from "vuex"
import { request } from "@alegradev/smile-ui-alegra"
import { baseApiUrl, firstStepsCookieBaseName, checkAllStepsCompleted } from "@/utils/appHelpers"

export default {
  components: {
    NewFirstStepsComponent
  },
  data: () => ({
    loading: true,
    show: true,
    invoice: false,
    contacts: false,
    items: false,
    users: false,
    achievements: [
      {
        id: "badge-fe",
        title: "electronicInvoicing.title",
        actions: ["skip", "start"],
        link: "/fepe-wizard",
        stepName: "electronic invoice"
      },
      {
        id: "badge-fv",
        title: "firstInvoice.title",
        actions: ["start"],
        link: "/invoice/add",
        stepName: "first invoice"
      },
      {
        id: "badge-item",
        title: "organizeInventory.title",
        actions: ["start"],
        link: "/item/add",
        stepName: "create product"
      },
      {
        id: "badge-contact",
        title: "manageContacts.title",
        actions: ["start"],
        link: "/client/add",
        stepName: "create client"
      },
      {
        id: "badge-user",
        title: "manageUsers.title",
        actions: ["start"],
        link: "/user/add",
        stepName: "create user"
      },
    ],
    skipModal: {
      title: "Actívate a tu ritmo",
      text: "Puedes realizar el proceso de habilitación ante la SUNAT en cualquier momento ingresando al menú Factura electrónica",
      help: "https://ayuda.alegra.com/es/facturaci%C3%B3n-electr%C3%B3nica-en-alegra-per%C3%BA"
    }
  }),
  computed: {
    ...mapGetters({
      company: "session/company",
      user: "session/user"
    }),

    notShowAgainCookieName() {
      return `${firstStepsCookieBaseName}-no-show-${this.company.id}`
    },
  },
  created() {
    if (this.user.role !== "admin") {
      this.achievements = this.achievements.filter(achievement => achievement.id !== "badge-user")
    }
  },
  async mounted() {
    if(Cookies.get(this.notShowAgainCookieName)) {
      this.loading = false;
      this.show = false;
      return;
    }

    await this.init()
    this.setInfo()
  },
  methods: {
    ...mapActions({
      setLoading: "config/setLoading",
      setError: "config/setError"
    }),
    async init() {
      this.loading = true

      try {
        const respInvoices = await request({
          url: `${baseApiUrl}v1/invoices?limit=1`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.invoice = respInvoices.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respItems = await request({
          url: `${baseApiUrl}v1/items?limit=1&mode=simple`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.items = respItems.data.length > 0 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respContacts = await request({
          url: `${baseApiUrl}v1/contacts?start=0&limit=2`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.contacts = respContacts.data.length > 1 ? true : false
      } catch (error) {
        this.setError(true)
      }

      try {
        const respUsers = await request({
          url: `${baseApiUrl}v1/users?limit=2`,
          method: "get",
          headers: {
            "X-Data-Source": "alegra-accounting"
          }
        })
        this.users = respUsers.data.length > 1 ? true : false
      } catch (error) {
        this.setError(true)
      }

      //this.loading = false
    },

    getIndex(id) {
      return this.achievements.findIndex(achievement => achievement.id === id)
    },

    setInfo() {
      let fe = this.getIndex("badge-fe");
      let invoice = this.getIndex("badge-fv");
      let items = this.getIndex("badge-item");
      let contacts = this.getIndex("badge-contact");
      let users = this.getIndex("badge-user");

      if (fe !== -1 && this.company.settings.electronicInvoicing) {
        this.achievements[fe].actions = ["completed"]
      }

      if (invoice !== -1 && this.invoice) {
        this.achievements[invoice].actions = ["completed"]
      }

      if (items !== -1 && this.items) {
        this.achievements[items].actions = ["completed"]
      }

      if (contacts !== -1 && this.contacts) {
        this.achievements[contacts].actions = ["completed"]
      }

      if (users !== -1 && this.users) {
        this.achievements[users].actions = ["completed"]
      }


      if(!Cookies.get(this.notShowAgainCookieName) &&
         checkAllStepsCompleted(this.achievements) &&
         isBefore(new Date(this.company.registryDate.split(" ")[0]), new Date("2023/01/09"))) {

          Cookies.set(this.notShowAgainCookieName, true, {
            expires: 30,
            domain: ".alegra.com",
            sameSite: "strict",
            secure: true
          })

          this.show = false;
      }

      this.loading = false

    }
  }
}
</script>
